import React, { useContext, useState, useEffect } from "react";
import { Trans } from "@lingui/macro";
import { strapiHost, useImagePreloader } from "../utils";
import {
  styled,
  media,
  fonts,
  angle,
  pink,
  white,
  style,
  width
} from "../styles";
import { Page, Content, Link, Image, navigate } from "../components";

type Image = {
  height: number;
  width: number;
  url: string;
  alt: string | null;
};

type FeatureArticle = {
  id: number;
  title: string;
  slug: string;
  published: string;
  html: HTMLDivElement;
  image: Image;
};

const PressAndNews: React.FC<{}> = props => {
  const [isEnglish, setIsEnglish] = useState(
    props.location.pathname.includes("/en")
  );
  const [pageData, setPageData] = useState({
    pageTitle: "",
    singleArticleHeading: "",
    multipleArticleHeading: "",
    multiplePostHeading: "",
    contact: {
      heading: "",
      fullName: "",
      mailAddress: ""
    },
    pressDistribution: {
      heading: "",
      text: "",
      disclaimer: {
        html: { innerHTML: "" }
      },
      successNotification: "",
      errorNotification: ""
    },
    downloads: {
      heading: "",
      text: "",
      linkText: "",
      link: ""
    }
  });
  const [featureArticle, setFeatureArticle] = useState<FeatureArticle | {}>({});
  const [articles, setArticles] = useState([]);
  const [pressRelease, setPressRelease] = useState([]);
  const [email, setEmail] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const { preloadImages, pending } = useImagePreloader();

  // Fetch Articles and transform fetched data
  async function fetchAndTransformArticles() {
    // get the html from ckeditor
    const sanitizeHtml = (html: string) => {
      const tmpDiv = document.createElement("div");
      const div = document.createElement("div");
      tmpDiv.innerHTML = html;
      Array.from(tmpDiv.children).forEach(item => {
        item.removeAttribute("style");
        // to remove empty element
        // if(!item.textContent?.match(/\w/gi)) {
        //   item.remove()
        // }
      });
      const firstParagraph = tmpDiv.getElementsByTagName("p")[0];
      let textArr = firstParagraph.textContent
        ? firstParagraph.textContent.split(" ")
        : [];
      if (textArr.length > 23) {
        textArr = textArr.slice(0, 24);
      }
      textArr.push("...");
      const text = textArr.join(" ");
      firstParagraph.textContent = text;
      div.append(firstParagraph);
      return div;
    };

    // get the articles smaller image if present
    const getArticleImage = (image: any) => {
      let img = {};
      if (image?.data?.attributes?.formats?.medium) {
        img = {
          height: image.data.attributes.formats.medium.height,
          width: image.data.attributes.formats.medium.width,
          url: strapiHost + image.data.attributes.formats.medium.url,
          alt: image.data.attributes.alternativeText
        };
      } else {
        img = {
          height: image.data.attributes.height,
          width: image.data.attributes.width,
          url: strapiHost + image.data.attributes.url,
          alt: image.data.attributes.alternativeText
        };
      }
      return img;
    };

    // convert published date
    function pubDateConverter(date: string) {
      const tmpDate = new Date(date);
      const dateString = tmpDate.toLocaleDateString("de", {
        month: "long",
        day: "numeric",
        year: "numeric"
      });
      return dateString;
    }

    // function getLocalizedProp(isEnglish: boolean, item: any, prop: string) {
    //   if (isEnglish && item.attributes.localizations.data.length) {
    //     return item.attributes.localizations.data[0].attributes[prop];
    //   }
    //   return item.attributes[prop];
    // }

    try {
      const url = isEnglish
        ? `${strapiHost}/api/artikels?populate=*&locale[0]=en`
        : `${strapiHost}/api/artikels?populate=*`;
      const response = await fetch(url);
      const jsonData = await response.json();
      // console.log(jsonData);
      const transformedData = jsonData.data.map((item: any) => {
        return {
          id: item.id,
          image: getArticleImage(item.attributes.Bild),
          // title: isEnglish ? item.attributes.localizations.data.lenght .Titel : item.attributes.Titel,
          title: item.attributes.Titel,
          // slug: item.attributes.Slug,
          slug: item.attributes.Slug,
          published: pubDateConverter(item.attributes.publishedAt),
          // html: sanitizeHtml(item.attributes.Text)
          html: sanitizeHtml(item.attributes.Text)
        };
      });
      const images = transformedData.map((items: FeatureArticle) => {
        return items.image.url;
      });
      await preloadImages(images);
      setFeatureArticle(transformedData[0]);
      setArticles(transformedData.slice(1));
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchPressRelease() {
    try {
      const url = isEnglish
        ? `${strapiHost}/api/pressemitteilungs?locale[0]=en`
        : `${strapiHost}/api/pressemitteilungs`;
      const response = await fetch(url);
      const jsonData = await response.json();
      // console.log({ jsonData });
      setPressRelease(jsonData.data);
    } catch (error) {}
  }

  async function fetchPageData() {
    // /api/news-presse-page
    try {
      const url = isEnglish
        ? `${strapiHost}/api/news-presse-page?populate=*&locale[0]=en`
        : `${strapiHost}/api/news-presse-page?populate=*`;
      const response = await fetch(url);
      const jsonData = await response.json();
      console.log({ jsonData });
      const {
        pageTitle,
        singleArticleHeading,
        multipleArticleHeading,
        multiplePostHeading,
        contact,
        pressDistribution,
        downloads
      } = jsonData.data.attributes;
      setPageData({
        pageTitle,
        singleArticleHeading,
        multipleArticleHeading,
        multiplePostHeading,
        contact,
        pressDistribution: {
          ...pressDistribution,
          disclaimer: {
            html: {
              innerHTML: convertMarkdownLinks(pressDistribution.disclaimer)
            }
          }
        },
        downloads
      });
    } catch (error) {}
  }

  useEffect(() => {
    // console.log("mounted presse and news");
    fetchPageData();
    fetchAndTransformArticles();
    fetchPressRelease();
    // console.log(props.location);
    // console.log(isEnglish);
    // console.log(featureArticle);
    // console.log(articles);
  }, []);

  useEffect(() => {
    console.log(pageData);
  }, [pageData]);

  // useEffect(() => {
  //   console.log({ articles });
  //   console.log({ featureArticle });
  // }, [articles, featureArticle]);

  function setInnerHTML(html) {
    return {
      __html: html.innerHTML
    };
  }

  function onChangeEmail(evt: any) {
    setEmail(evt.target.value);
    if (evt.target.validity.valid && evt.target.value.length) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }

  function enableFormSubmit(): boolean {
    if (buttonDisabled === false && isChecked === true) {
      return false;
    }
    return true;
  }

  async function onFormSubmit(evt: any) {
    evt.preventDefault();
    setShowErrorNotification(false);
    setShowSuccessNotification(false);
    const data = {
      Email: email
    };

    try {
      const response = await fetch(`${strapiHost}/api/presseverteilers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ data })
      });

      const jsonResponse = await response.json();
      if (jsonResponse.data) {
        setShowSuccessNotification(true);

        // try {
        //   const mailResponse = await fetch(
        //     "https://germanwahnsinn.de/pressemitteilung-verteiler",
        //     {
        //       method: "POST",
        //       headers: {
        //         "Content-Type": "application/json"
        //       },
        //       body: JSON.stringify(jsonResponse.data)
        //     }
        //   );
        //   const jsonMailResponse = await mailResponse.json();
        //   console.log(jsonMailResponse);
        // } catch (error) {}
      } else {
        setShowErrorNotification(true);
        if (jsonResponse?.error) {
          console.error(
            "Status:",
            jsonResponse.error.status,
            "| Type:",
            jsonResponse.error.name,
            "| Path:",
            jsonResponse.error?.details.errors?.[0]?.path.join(","),
            "| Message:",
            jsonResponse.error.message
          );
        }
      }
      setButtonDisabled(true);
      setEmail("");
      setIsChecked(false);
    } catch (error) {
      setShowErrorNotification(true);
      console.error("Error:", error);
    }
  }

  function convertMarkdownLinks(text) {
    return text.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>');
  }

  return (
    <Page
      title="Presse & News"
      seoTitle="Tonstudio | Musik, Sounddesign & Sprachaufnahmen | German Wahnsinn"
      description="Wir komponieren und vertonen für euch und für alle Werbeagenturen, Filmproduktionen, Verlage, Label und Musiker."
      keywords={[
        "German Wahnsinn",
        "Tonstudio",
        "Musikstudio",
        "Sounddesign",
        "Sprachaufnahmen",
        "Audio",
        "Postproduction",
        "Hörbücher",
        "Hörspiele",
        "Podcasts"
      ]}
      sideLabel={false}
      {...props}
    >
      <Content>
        <Loader className={pending ? "" : "hide"}>
          <div className="loader"></div>
        </Loader>

        <HomeContainer id="press-and-news">
          <HomeHeading>{pageData.pageTitle}</HomeHeading>

          <FlexContainer>
            <FlexChild>
              <div>
                <HeadingH2 className="heading2">
                  {pageData.singleArticleHeading}
                </HeadingH2>
                <div>
                  {featureArticle && featureArticle?.image ? (
                    <FeatureArticleContainer>
                      <div
                        className="poster"
                        onClick={() =>
                          navigate(
                            isEnglish
                              ? `/news/${featureArticle.slug}`
                              : `/news/${featureArticle.slug}`,
                            {
                              state: { articleId: featureArticle.id }
                            }
                          )
                        }
                      >
                        <div className="poster-background"></div>
                        <div
                          className="poster-image"
                          style={{
                            backgroundImage: `url(${featureArticle.image.url})`
                          }}
                        ></div>
                        <img
                          src={featureArticle.image.url}
                          alt={featureArticle.image.alt}
                          width={featureArticle.image.width}
                          height={featureArticle.image.height}
                        />
                      </div>
                      <small className="date">{featureArticle.published}</small>
                      <h3>
                        <Link
                          to={`/news/${featureArticle.slug}`}
                          state={{ articleId: featureArticle.id }}
                        >
                          {featureArticle.title}
                        </Link>
                      </h3>
                      <div
                        className="excerpt"
                        dangerouslySetInnerHTML={setInnerHTML(
                          featureArticle.html
                        )}
                      ></div>
                      <Link
                        to={`/news/${featureArticle.slug}`}
                        state={{ articleId: featureArticle.id }}
                      >
                        {isEnglish ? "Read more" : "Mehr Erfahren"}
                      </Link>
                    </FeatureArticleContainer>
                  ) : null}
                </div>
              </div>
            </FlexChild>
            <FlexChild>
              <div>
                <HeadingH2 className="heading2">
                  {pageData.multipleArticleHeading}
                </HeadingH2>
                <ScrollableContainer>
                  <ul>
                    {articles && Array.isArray(articles)
                      ? articles.map(article => (
                          <li key={article.id}>
                            <ArticleItem>
                              <div
                                className="poster"
                                onClick={() =>
                                  navigate(`/news/${article.slug}`, {
                                    state: { articleId: article.id }
                                  })
                                }
                              >
                                <div className="poster-background"></div>
                                <div
                                  className="poster-image"
                                  style={{
                                    backgroundImage: `url(${article.image.url})`
                                  }}
                                ></div>
                                <img
                                  src={article.image.url}
                                  alt={article.image.alt}
                                  width={article.image.width}
                                  height={article.image.height}
                                />
                              </div>
                              <div>
                                <small className="date">
                                  {article.published}
                                </small>
                                <h3>
                                  <Link
                                    to={`/news/${article.slug}`}
                                    state={{ articleId: article.id }}
                                  >
                                    {article.title}
                                  </Link>
                                </h3>
                              </div>
                            </ArticleItem>
                          </li>
                        ))
                      : null}
                  </ul>
                </ScrollableContainer>
              </div>
            </FlexChild>
          </FlexContainer>
          <FlexContainer>
            <FlexChild>
              <div>
                <HeadingH2 className="heading2">
                  {pageData.multiplePostHeading}
                </HeadingH2>
                <ScrollableContainerTwo>
                  <ul>
                    {pressRelease && Array.isArray(pressRelease)
                      ? pressRelease.map(release => (
                          <li
                            key={`${release.attributes.id}-${release.attributes.Titel}`}
                          >
                            <PressReleaseItem>
                              <div>
                                <h3>
                                  <a
                                    href={release.attributes.Link}
                                    target="_blank"
                                  >
                                    {release.attributes.Titel}
                                  </a>
                                </h3>
                              </div>
                            </PressReleaseItem>
                          </li>
                        ))
                      : null}
                  </ul>
                </ScrollableContainerTwo>
              </div>
            </FlexChild>

            <FlexChild>
              <div>
                <PresseKontakt>
                  <HeadingH2>{pageData?.contact.heading}</HeadingH2>
                  <p>{pageData?.contact.fullName}</p>
                  <p>
                    <a href={`mailto:${pageData?.contact.mailAddress}`}>
                      {pageData?.contact.mailAddress}
                    </a>
                  </p>
                </PresseKontakt>
                <PresseKontakt>
                  <HeadingH2>{pageData?.pressDistribution.heading}</HeadingH2>
                  <p>{pageData?.pressDistribution.text}</p>
                  <form onSubmit={onFormSubmit}>
                    <input
                      type="email"
                      value={email}
                      onChange={onChangeEmail}
                      placeholder={
                        isEnglish ? "E-mail address" : "E-Mail-Adresse"
                      }
                    />
                    {showSuccessNotification ? (
                      <small>
                        {pageData?.pressDistribution.successNotification}
                      </small>
                    ) : null}
                    {showErrorNotification ? (
                      <small>
                        {pageData?.pressDistribution.errorNotification}
                      </small>
                    ) : null}
                    <div className="buttonControl">
                      <div>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={() => {
                            setIsChecked(state => !state);
                          }}
                        />
                        <label
                          htmlFor="confirm"
                          dangerouslySetInnerHTML={setInnerHTML(
                            pageData?.pressDistribution.disclaimer.html
                          )}
                        ></label>
                      </div>
                      <button disabled={enableFormSubmit()} type="submit">
                        {isEnglish ? "Submit" : "Senden"}
                      </button>
                    </div>
                  </form>
                </PresseKontakt>
                <PresseKontakt>
                  <HeadingH2>{pageData?.downloads.heading}</HeadingH2>
                  <p>{pageData?.downloads.text}</p>
                  <p>
                    <a
                      style={{ textDecoration: "underline" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={pageData?.downloads.link}
                    >
                      {pageData?.downloads.linkText}
                    </a>
                  </p>
                </PresseKontakt>
              </div>
            </FlexChild>
          </FlexContainer>
        </HomeContainer>
      </Content>
    </Page>
  );
};

const Loader = styled.div`
  height: 100vh;
  display: grid;
  justify-items: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  left: 0;
  user-select: none;
  background-color: #f2f4f7;
  z-index: 10;
  div.loader {
    width: 40px;
    aspect-ratio: 1;
    --c: linear-gradient(#ff0064 0 0);
    --m: radial-gradient(farthest-side, #ff0064 92%, #0000);
    background: var(--m) center / 12px 12px,
      var(--c) left 50% top -20px/8px 16px,
      var(--c) left 50% bottom -20px/8px 16px,
      var(--c) top 50% left -20px/16px 8px,
      var(--c) top 50% right -20px/16px 8px;
    background-repeat: no-repeat;
    animation: l18-1 1.5s infinite, l18-2 1.5s infinite;
  }

  @keyframes l18-1 {
    30%,
    70% {
      background-position: center, left 50% top calc(50% - 8px),
        left 50% bottom calc(50% - 8px), top 50% left calc(50% - 8px),
        top 50% right calc(50% - 8px);
    }
  }
  @keyframes l18-2 {
    0%,
    40% {
      transform: rotate(0);
    }
    60%,
    100% {
      transform: rotate(90deg);
    }
  }

  @keyframes hideLoader {
    0% {
      opacity: 1;
    }
    40%,
    100% {
      opacity: 0;
    }
  }

  @keyframes moveLoader {
    0%,
    80% {
      height: 100vh;
      width: 100vw;
      left: 0;
    }
    100% {
      height: 0;
      width: 0;
      left: -5000;
    }
  }

  &.hide {
    animation: hideLoader 1.5s ease-in-out 0.75s 1 normal forwards running,
      moveLoader 1s ease-in-out 1.6s 1 normal forwards running;
  }
`;

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  .ccm-settings-summoner {
    display: none;
  }
`;

const ArticleHeading = styled.h1`
  margin: 0 0 1rem 0;
  font-size: 2rem;
  ${media.tablet} {
    font-size: 2.5rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;

  ${media.tablet} {
    padding-top: 3rem;
  }

  ${media.desktop} {
    flex-direction: row;
    gap: 3rem;
  }
`;

const FlexChild = styled.div`
  flex: 1;
  > div {
    margin-bottom: 0rem;
    padding-bottom: 2rem;
    .heading2 {
      margin: 0;
    }
    > div {
      margin: 2rem 0 0 0;
    }
  }

  ${media.tablet} {
    > div {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
    }
  }

  ${media.desktop} {
    :first-child {
      flex: 0 1 50%;
    }
  }
`;

const FeatureArticleContainer = styled.article`
  border-bottom: 2px solid #ff006438;
  padding-bottom: 1.5rem;
  .poster {
    position: relative;
    margin: 0 0 2rem;
    padding: 0;
    cursor: pointer;
  }
  .poster .poster-background {
    position: absolute;
    background-color: ${pink};
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform: scale(1, 1.025) rotate(0);
    z-index: 1;
  }
  .poster .poster-image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transform-origin: center;
    transform: scale(0.96) rotate(0) translate(0, 0);
    z-index: 2;
  }
  .poster img {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    visibility: hidden;
  }

  small {
    font-size: 1.1rem;
  }

  h3 {
    margin: 1rem 0;
    line-height: 2rem;
  }

  a {
    margin: 0;
    font-size: 1.2rem;
    &:before {
      content: ">";
      font-family: ${fonts.vario};
      text-decoration: none;

      color: ${pink};
      cursor: pointer;
      vertical-align: baseline;
      text-transform: uppercase;

      &:hover {
        color: ${pink};
        animation: wahnsinn 0.3s linear 0s infinite;
      }
    }
  }

  h3 a {
    font-size: 1.35rem;
    &:before {
      content: "";
    }
  }

  ${media.tablet} {
    padding-bottom: 2rem;
  }

  ${media.laptop} {
    .poster {
      margin: 0 0 3rem 0;
      padding: 1.5rem 0 0 0;
    }
    .poster .poster-background {
      width: 66%;
      transform-origin: left bottom;
      transform: scale(0.96, 1) rotate(-3deg);
    }
    .poster .poster-image {
      width: 66%;
      transform-origin: left bottom;
      transform: scale(0.89) rotate(-1.95deg) translate(22px, -28px);
    }
    .poster img {
      width: 66%;
    }
  }

  ${media.desktop} {
    .poster {
      margin: 0 0 3.5rem 0;
      padding: 1.5rem 0 0 0;
    }
    .poster .poster-background {
      width: 88%;
      transform: scale(0.96, 1) rotate(-3deg);
    }
    .poster .poster-image {
      width: 88%;
      transform: scale(0.9) rotate(-2.5deg) translate(18px, -21px);
    }
    .poster img {
      width: 88%;
    }
    h3 a {
      font-size: 1.75rem;
    }
  }
`;

const ArticleItem = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  .poster {
    position: relative;
    margin: 0 0 2rem;
    cursor: pointer;
  }
  .poster .poster-background {
    position: absolute;
    background-color: ${pink};
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform: scale(1, 1.025);
    z-index: 1;
  }
  .poster .poster-image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transform: scale(0.96);
    z-index: 2;
  }
  .poster img {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    visibility: hidden;
  }

  small {
    font-size: 1rem;
  }

  h3 {
    margin: 1rem 0 2rem;
    line-height: 1.5rem;
  }

  a {
    margin: 0;

    &:before {
      content: ">";
      font-family: ${fonts.vario};
      text-decoration: none;

      color: ${pink};
      cursor: pointer;
      vertical-align: baseline;
      text-transform: uppercase;

      &:hover {
        color: ${pink};
        animation: wahnsinn 0.3s linear 0s infinite;
      }
    }
  }

  h3 a {
    font-size: 1.35rem;
    &:before {
      content: "";
    }
  }

  ${media.tablet} {
    grid-template-columns: 65% 35%;
    .poster {
      order: 1;
    }
    .poster .poster-background {
      transform: scale(0.96, 1.025);
    }
    .poster .poster-image {
      transform: scale(0.9);
    }
  }

  ${media.desktop} {
    min-height: 180px;
    grid-template-columns: 35% 65%;
    grid-column-gap: 1rem;
    .poster {
      order: 0;
    }
    .poster + div {
      padding: 0 1rem 0 0;
    }
    .poster .poster-image {
      transform: scale(0.9);
    }
  }
`;

const PressReleaseItem = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  h3 {
    margin: 1rem 0 2rem;
    line-height: 1.5rem;
  }

  a {
    margin: 0;

    &:before {
      content: ">";
      font-family: ${fonts.vario};
      text-decoration: none;

      color: ${pink};
      cursor: pointer;
      vertical-align: baseline;
      text-transform: uppercase;

      &:hover {
        color: ${pink};
        animation: wahnsinn 0.3s linear 0s infinite;
      }
    }
  }

  h3 a {
    font-size: 1.35rem;
  }
`;

const PresseKontakt = styled.div`
  background: ${pink};
  padding: 2rem 1.75rem;
  color: white;
  margin-bottom: 2rem;

  > h2 {
    color: inherit;
    font-size: 1.62rem;
  }
  > p {
    color: inherit;
    margin: 0 0 0.5rem;
    font-size: 1.2rem;
    > a {
      font-family: NittiGrotesk-SemiLight, sans-serif;
      font-weight: 350;
      font-size: 1.2rem;
      line-height: 1.3;
      text-transform: initial;
      color: inherit;
    }
    > a:hover {
      color: inherit;
    }
  }

  > form {
    margin-top: 2rem;
    > div.buttonControl {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      > div {
        display: flex;
        label {
          font-family: "NittiGrotesk-SemiLight", sans-serif;
          line-height: 1rem;
          a {
            text-transform: none;
            text-decoration: underline;
            font-family: inherit;
          }
          a:hover {
            color: ${white};
          }
        }
        input[type="checkbox"] {
          align-self: flex-start;
          margin: 0 0.75rem 0 0;
          min-height: initial;
          width: initial;
          display: block;
        }
      }

      button {
        margin-top: 1.25rem;
        padding-left: 2rem;
        padding-right: 2rem;
        min-height: 42px;
      }
      button:hover {
        background-color: #000;
      }
      button:disabled {
        opacity: 0.5;
        background-color: #ff0064;
        cursor: not-allowed;
        border: 1px solid ${white};
        border-radius: 4px;
      }
    }
    input {
      min-height: 42px;
      width: 100%;
      margin-bottom: 1rem;
    }
    small {
      font-size: 1rem;
    }
    input:focus:invalid {
    }
  }

  ${media.tablet} {
    padding: 2rem 3.25rem;
    > p {
      font-size: 1.6rem;
      > a {
        font-size: 1.6rem;
      }
    }
  }
`;

const ScrollableContainer = styled.div`
  --scroll-item-height: 178px;
  --items-to-show: 4;
  cursor: initial;
  height: auto;

  ${media.desktop} {
    cursor: row-resize;
    height: calc(var(--scroll-item-height) * var(--items-to-show));
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overscroll-behavior: contain;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
`;

const ScrollableContainerTwo = styled.div`
  --scroll-item-height: 99px;
  --items-to-show: 5;
  cursor: initial;
  height: auto;

  ${media.desktop} {
    cursor: row-resize;
    height: calc(var(--scroll-item-height) * var(--items-to-show));
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overscroll-behavior: contain;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
`;

const HomeHeading = styled.h1`
  z-index: 1;
  margin-bottom: 2rem;
  margin-left: 0;
  padding-top: 2rem;
  font-size: 37px;
  text-align: center;

  span::before {
    content: "\\A";
    white-space: pre;
  }

  ${media.tablet} {
    margin-bottom: 3rem;
    margin-left: 0;
    padding-top: 1rem;
    font-size: 67px;
    ${angle(-4)};
  }

  ${media.laptop} {
    font-size: 72px;
    margin-left: 2.5rem;
    text-align: left;
  }

  ${media.desktop} {
    margin-left: 0;
  }
`;

const HeadingH2 = styled.h2`
  margin: 0;
  font-size: 22px;
  margin-bottom: 1.25rem;

  ${media.tablet} {
    font-size: 32px;
    margin-bottom: 2rem;
  }
`;

export default PressAndNews;
